<template>
  <swiper-slide
    class="h-full w-full overflow-auto no-scrollbar"
    style="width: 90%"
  >
    <PlanYTDAndY1 />

    <div
      class="h-full w-full rounded-lg module p-2 flex flex-col mt-2 relative"
    >
      <loader :loading="loadingAll" />

      <div class="h-8 flex flex-row justify-between items-center">
        <span class="text-sm font-semibold text-font-gray">SL</span>
        <div class="text-font-gray mr-1 text-xs ml-auto">Sort By</div>
        <div class="w-30">
          <multiselect v-model="sortBy" :options="optionsFilter" label="name" />
        </div>
        <div
          class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer mx-2"
          :class="{ 'bg-aux-15 text-aux': all, 'bg-filters': !all }"
          @click="toggleSlSelectedItemsAll()"
        >
          <span class="text-3xs">ALL</span>
        </div>
      </div>

      <div
        v-if="!loadingsl"
        class="h-full bg-module-top mt-2 overflow-auto no-scrollbar"
      >
        <div
          v-for="(el, index) in slElementOrdered(slElements)"
          :key="index"
          class="h-32 box flex flex-col mb-2"
        >
          <div class="h-6 flex flex-row justify-start items-center p-1">
            <div
              v-if="legendColors[el.id]"
              class="h-full w-1 rounded-full mr-2"
              :style="'background-color:' + legendColors[el.id].color"
            ></div>

            <span class="text-xs font-semibold text-white">{{ el.name }}</span>

            <div
              class="h-6 w-6 rounded-lg cursor-pointer ml-auto flex flex-col justify-center items-center"
              :class="includesInSLSelected(el.id)"
              @click="toggleSlSelectedItems(el.id)"
            >
              <i class="mdi mdi-ray-start-arrow text-xs"></i>
            </div>
          </div>

          <div class="h-6 mt-2 flex flex-row justify-center items-center">
            <div class="h-1 w-1 rounded-full bg-green mr-1"></div>

            <span class="text-3xs mr-2 text-font-gray"
              >Current Chargeability</span
            >

            <span class="text-3xs mr-2 font-semibold"
              >{{ el.ytd | numberFormat(2) }}%</span
            >

            <div class="h-1 w-1 rounded-full bg-purple mr-1"></div>

            <span class="text-3xs mr-2 text-font-gray">Y-1 Chargeability</span>

            <span class="text-3xs font-semibold"
              >{{ el.ytd_1 | numberFormat(2) }}%</span
            >
          </div>

          <div v-if="el.mtd || el.mtd_1" class="flex flex-1 min-h-0 mt-1">
            <chart-col-compare-small
              class="-mt-2"
              chartId="chart"
              :marginchart="true"
              :data="[el.mtd, el.mtd_1]"
              :names="['Current Chargeability', 'Y-1 Chargeability']"
            />
          </div>
        </div>
      </div>

      <div v-else class="h-full relative">
        <loader :loading="loadingsl" />
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import { TwelveMonthsChart } from "@/utils";
import { state, actions } from "@/store";
import Loader from "../../Globals/Loader.vue";
const PlanYTDAndY1 = () =>
  import("@/components/Mobile/Chargeability/PlanYTDAndY1");

export default {
  name: "SL",
  components: {
    PlanYTDAndY1,
    Loader,
  },
  data() {
    return {
      filters: false,
      activeOption: [],
      loadingsl: false,
      slElements: false,
      elementsSL: false,
      all: false,
      loadingAll: false,
      sortBy: { name: "Name", value: "name", order: "asc" },
      optionsFilter: [
        {
          name: "Name",
          value: "name",
          order: "asc",
        },
        {
          name: "Current Chargeability",
          value: "ytd",
          order: "desc",
        },
        {
          name: "Y-1 Chargeability",
          value: "ytd_1",
          order: "desc",
        },
      ],
    };
  },
  methods: {
    setSL(sl) {
      // setear la sl
      this.$emit("setSL", sl);
      this.$router.push("Smus");
    },
    add(option) {
      if (this.activeOption.length > 0) {
        let error = false;
        for (let index = 0; index < this.activeOption.length; index++) {
          if (option === this.activeOption[index]) {
            error = true;
            this.activeOption.splice(index, 1);
          }
        }
        if (!error) {
          this.activeOption.push(option);
        }
      } else {
        this.activeOption.push(option);
      }
    },
    ifActivated(option) {
      let test;
      for (let index = 0; index < this.activeOption.length; index++) {
        if (this.activeOption[index] === option) {
          test = true;
        }
      }
      if (test) {
        switch (option) {
          case "ASS":
            return "bg-purple-30";
            break;
          case "ADV":
            return "bg-good-30";
            break;
          case "PAS":
            return "bg-violet_30";
            break;
          case "TAX":
            return "bg-warning-30";
            break;
          case "TAS":
            return "bg-green-30";
            break;
        }
      } else {
        return "bg-box-40";
      }
    },
    load() {
      this.loadingsl = true;
      this.axios
        .get("chargeability/slytd", { params: this.params })
        .then((response) => {
          this.loadingsl = false;
          this.slElements = response.data.data;
        });
      this.axios
        .get("inter_intra/sl", { params: this.params })
        .then((response) => {
          this.elementsSL = response.data.data;
          this.$setColorsBySector(this.elementsSL);
          state.slSelectedItems = [];
        });
    },
    includesInSLSelected(sl) {
      let test = false;

      for (let index = 0; index < this.slSelectedItems.length; index++) {
        if (this.slSelectedItems[index] == sl) {
          test = true;
        }
      }

      if (test == true) {
        return "bg-aux-15 text-aux";
      } else {
        return "bg-filters-80 text-font-gray";
      }
    },
    toggleSlSelectedItems(item) {
      if (!this.slSelectedItems.includes(item)) {
        actions.toggleSlSelectedItems(item);
        this.$emit("nextPage");
      } else {
        actions.toggleSlSelectedItems(item);
      }
    },
    randomChart() {
      return [
        [
          [1577836800000, Math.floor(Math.random() * 70)],
          [1580515200000, Math.floor(Math.random() * 70)],
          [1583020800000, Math.floor(Math.random() * 70)],
          [1585699200000, Math.floor(Math.random() * 70)],
          [1588291200000, Math.floor(Math.random() * 70)],
          [1590969600000, Math.floor(Math.random() * 70)],
          [1593561600000, Math.floor(Math.random() * 70)],
          [1596240000000, Math.floor(Math.random() * 70)],
          [1598918400000, Math.floor(Math.random() * 70)],
        ],
        [
          [1577836800000, Math.floor(Math.random() * 50)],
          [1580515200000, Math.floor(Math.random() * 50)],
          [1583020800000, Math.floor(Math.random() * 50)],
          [1585699200000, Math.floor(Math.random() * 50)],
          [1588291200000, Math.floor(Math.random() * 50)],
          [1590969600000, Math.floor(Math.random() * 50)],
          [1593561600000, Math.floor(Math.random() * 50)],
          [1596240000000, Math.floor(Math.random() * 50)],
          [1598918400000, Math.floor(Math.random() * 50)],
        ],
      ];
    },
    toggleSlSelectedItemsAll() {
      this.loadingAll = true;

      this.all = !this.all;

      if (!this.all) {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          state.slSelectedItems = state.slSelectedItems.filter(
            (item) => item !== Object.keys(this.legendColors)[index]
          );
        }
      } else {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          state.slSelectedItems.push(Object.keys(this.legendColors)[index]);
        }
      }

      this.loadingAll = false;

      if (this.all) {
        this.$emit("nextPage");
      }
    },
    slElementOrdered(data) {
      return _.orderBy(data, [this.sortBy.value], [this.sortBy.order]);
    },
  },
  computed: {
    legendColors() {
      return state.slLegendColors;
    },
    TwelveMonthsChartCompare() {
      return TwelveMonthsChart(2, false);
    },
    params() {
      return state.globalParams;
    },
    slSelectedItems() {
      return state.slSelectedItems;
    },
  },
  watch: {
    activeOption(n, o) {
      this.$emit("options", n);
    },
    params() {
      this.load();
    },
  },
  mounted() {
    if (this.params) {
      this.load();
    }
  },
};
</script>

<style scoped></style>
